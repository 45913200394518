<template>
   <section :class="classes" v-if="array != null && array.length > 0">
      <div class="container">
         <div class="section__head mb-20">
            <div class="w-100 section__title">
               <h3 class="text-center mb-1">{{ titulo }}</h3>
            </div>
         </div>
         <div class="product__slider t-nav owl-carousel">
            <produto v-for="(produto, index) in array" :key="index" :produto="produto" :index="index" :view="'Carousel'" />
         </div>
      </div>
   </section>
</template>

<script>

import $ from 'jquery'
import Vue from 'vue'
import produto from '@/components/produtos/Produto.vue'

export default {
   name: 'CarouselProdutos',
   props: ['titulo', 'array', 'classes'],
   watch: {
      array : function (newVal, oldVal) {
         if (newVal != null && newVal.length > 0 && JSON.stringify(oldVal) != JSON.stringify(newVal)) {
            this.configCarousel()
         }
      }
   },
   components: {
      produto
   },
   methods: {
      configCarousel : function () {
         if (this.array != null && this.array.length > 0) {
            Vue.nextTick(function() {
               $('.product__slider').owlCarousel({
                  margin: 8,
                  navText:['<button><i class="fal fa-angle-left"></i></button>','<button><i class="fal fa-angle-right"></i></button>'],
                  nav: true,
                  rewind: true,
                  merge: true,
                  autoWidth: true
               });
            }.bind(this));
         }
      }
   },
   mounted() {
      this.configCarousel()
   }
}

</script>