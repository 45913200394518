<template>
   <div class="col-12 my-2" v-if="sessao.tipo == 'Texto com imagem esquerda'">
      <img :src="sessao.foto" class="float-md-start rounded wpx-400 mx-auto d-block d-md-inline my-2 me-md-4 ms-md-0" />
      <p class="text-justify mb-0">{{ sessao.conteudo }}</p>
   </div>
   <div class="col-12 my-2" v-else-if="sessao.tipo == 'Texto com imagem direita'">
      <img :src="sessao.foto" class="float-md-end rounded wpx-400 mx-auto d-block d-md-inline my-2 ms-md-4 me-md-0" />
      <p class="text-justify mb-0">{{ sessao.conteudo }}</p>
   </div>
   <div class="col-12 text-center" v-else-if="sessao.tipo == 'Imagem'">
      <img :src="sessao.foto" class="wpx-800" />
   </div>
   <div class="col-12 my-2" v-else-if="sessao.tipo == 'Texto'">
      <p class="text-justify mb-0">{{ sessao.conteudo }}</p>
   </div>
   <div class="col-12 mt-4 mb-2" v-else-if="sessao.tipo == 'Subtítulo'">
      <h5 class="text-center mb-0">{{ sessao.conteudo }}</h5>
   </div>
   <div class="col-12" v-else-if="sessao.tipo == 'Vídeo'">
      <div class="ratio ratio-16x9" v-if="String(sessao.conteudo).startsWith('https://www.youtube.com/embed/')">
         <iframe class="embed-responsive-item" :src="sessao.conteudo" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      <div class="ratio ratio-16x9" v-else>
         <video class="embed-responsive-item" width="320" height="240" :src="sessao.conteudo +'#t=0.5'" controls>Your browser does not support the video.</video>
      </div>
   </div>
</template>

<script>

export default {
   name: 'Sessão',
   props: ['sessao', 'index']
}

</script>

<style scoped>

img {
   aspect-ratio: 3/2;
   object-fit: cover;
   object-position: center;
}

</style>