<template>
   <main>
      <!-- breadcrumb area start -->
      <section class="breadcrumb__area pt-30 pb-10">
         <div class="container wpx-800">
            <div class="breadcrumb__wrapper">
               <nav aria-label="breadcrumb">
                  <ol class="breadcrumb mb-0">
                     <li class="breadcrumb-item"><router-link to="/"><i class="far fa-home color-theme me-2"></i>Home</router-link></li>
                     <li class="breadcrumb-item active" aria-current="page">Notícia</li>
                  </ol>
               </nav>
            </div>
         </div>
      </section>

		<!-- noticia area start -->
      <section class="noticia__area pt-20 pb-40">
         <div class="container wpx-800">
            <div class="row">
               <div class="col-12 mb-15">
                  <h1 class="color-theme font-20 mb-0">{{ noticia.titulo }}</h1>
               </div>
               <div class="col-12 d-flex align-items-center mb-2" v-if="noticia.data != null">
                  <span>{{ noticia.data.dayOfMonth.toString().padStart(2, '0') +'/'+ noticia.data.monthValue.toString().padStart(2, '0') +'/'+ noticia.data.year }}</span>
                  <span class="ms-1">{{ noticia.data.hour.toString().padStart(2, '0') +':'+ noticia.data.minute.toString().padStart(2, '0') }}</span>
                  <ul class="d-flex mt-1 ms-4">
                     <li class="me-3">
                        <a :href="'https://www.facebook.com/sharer/sharer.php?u='+ url" target="_blank">
                           <i class="fab fa-facebook-square text-facebook font-20"></i>
                        </a>
                     </li>
                     <li class="me-3">
                        <a :href="'https://wa.me/?text='+ url" target="_blank">
                           <i class="fab fa-whatsapp-square text-success font-20"></i>
                        </a>
                     </li>
                     <li>
                        <a :href="'mailto:?subject='+ noticia.titulo +' em CCM do Brasil &amp;body=Saiba mais em nosso site '+ url" target="_blank">
                           <i class="fas fa-envelope font-20"></i>
                        </a>
                     </li>
                  </ul>
               </div>
               <div class="col-12 mb-20">
                  <div class="w-img">
                     <img :src="noticia.banner == null ? '' : noticia.banner" alt="noticia" class="rounded" @error="imageError">
                  </div>
               </div>
               
               <sessao v-for="(sessao, index) in noticia.sessoes" :key="index" :sessao="sessao" />
            </div>
         </div>
      </section>

      <!-- relacionados area start -->
      <relacionados :array="noticia.relacionados" :titulo="'Relacionados'" :classes="'relacionados__area pt-40 pb-30 grey-bg'" />

      <!-- noticias area start -->
      <section class="noticias__area pt-30 pb-60 grey-bg" v-if="noticia.ultimasNoticias.length > 0">
         <div class="container">
            <div class="section__head mb-30">
               <div class="w-100 section__title">
                  <h3 class="text-center mb-1">Veja também</h3>
               </div>
            </div>
            <div class="row m-0">
					<noticia v-for="(noticia, index) in noticia.ultimasNoticias" :key="index" :noticia="noticia" :index="index" :view="'Linha'" />
            </div>
         </div>
      </section>
   </main>
</template>

<script>

import router from '@/router'
import { mapState } from 'vuex'
import sessao from '@/components/noticias/Sessao.vue'
import noticia from '@/components/noticias/Noticia.vue'
import relacionados from '@/components/CarouselProdutos.vue'

export default {
   name: 'Noticias',
   data : function () {
      return {
         url: window.location.href,
         noticia: {'id': null, 'titulo': null, 'tags': [], 'descricao': null, 'banner': null, 'sessoes': [], 'ultimasNoticias': [], 'relacionados': []}
      }
   },
   computed: {
		... mapState({
			urlRest: state => state.urlRest
		})
	},
   watch: { 
      '$route.params.id': {
         handler: function () {
            this.getNoticia()
         },
         deep: true,
         immediate: true
      }
   },
   components: {
      sessao, relacionados, noticia
   },
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      getNoticia : function () {
         let ref = this;
			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'get',
				url: this.urlRest +'site/getNoticia',
            params: {
					id: this.$route.params.id
				}
			}).then(response => {
            this.noticia = response.data

         }).catch(function (error) {
				if (error.response != undefined) {
               if (error.response.status == 400) {
                  router.push('/').catch(function(){})
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  })
               }
            } else {
					ref.$toast.fire({
						icon: 'error',
						title: error
					});
				}
			}).finally(() => {
            window.scrollTo(0, 0)
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
      }
   }
}

</script>

<style scoped>

.noticia__area img {
   aspect-ratio: 3/2;
	object-fit: cover;
   margin: 0 auto;
}

</style>