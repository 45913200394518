<template>
   <div class="product__item white-bg" :class="view == 'Carousel' ? 'carousel-250' : ''">
      <div class="product__thumb fix p-relative">
         <router-link :to="'/produto/'+ produto.codigo" class="w-img">
            <img :src="produto.foto == null ? '' : produto.foto" alt="produto" @error="imageError">
         </router-link>
      </div>
      <div class="product__content text-center">
         <h1 class="product-name mt-2 mb-2 mb-sm-1">
            <router-link :to="'/produto/'+ produto.codigo" class="product-item-link">{{ produto.nome }}</router-link>
         </h1>
         <div class="rating">Cód. {{ String(produto.codigo).substring(0, 2) +"-"+ String(produto.codigo).substring(2, String(produto.codigo).length) }}</div>
         <p class="description mb-1 d-none d-sm-flex">
            <small class="limitador-3 font-13">{{ produto.descricao == null || String(produto.descricao).trim() == '' ? 'Sem descrição' : produto.descricao }}</small>
         </p>
      </div>
   </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
   name: 'Produto',
   props: ['produto', 'view'],
   computed: {
		... mapState({
			logado: state => state.logado
		})
	},
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      }
   }
}

</script>

<style scoped>

img {
	aspect-ratio: 1 / 1;
	object-fit: contain;
}

@media (min-width: 767px) {
   .carousel-250 {
      min-width: 250px !important;
   }
}

@media (max-width: 767px) {
   .product-item-link {
      font-size: 13px !important;
   }
}

</style>